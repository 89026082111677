<!-- eslint-disable prettier/prettier -->
<template>
  <div
    class="absolute z-30 bg-white top-auto shadow px-6 grid report ml-14"
    style="
      width: calc(100vw - 152px);
      grid-template-rows: auto 1fr auto;
      bottom: 5px;
      left: 1vw;
      border-radius: 12px;
      z-index: 10;
    "
    :class="{ isBarShown: isBarShown }"
  >
    <div v-dragged="onDragged" class="flex border-b border-divider cursor-move">
      <div class="flex-1 flex">
        <div
          class="flex flex-col text-darkblue font-SourceSansPro"
          style="align-self: center; height: 40px"
        >
          <div class="flex">
            <p
              class="truncate text-darkblue text-base font-bold"
              style="font-size: 13px"
            >
              {{ tableReportName }}
            </p>
            <el-select
              v-if="tableData"
              v-model="selectedObject"
              class="custom-select"
              style="width: 200px; height: 24px"
              @change="switchObject"
              filterable
            >
              <el-option
                v-for="object in objectOrGroup"
                :key="object.id"
                :label="object.name"
                :value="object.id"
              ></el-option>
            </el-select>
          </div>
          <div class="flex cursor-pointer items-center">
            <p class="report-date">
              <template v-if="tableType === 'messages'">
                {{ last24hours }}
              </template>
              <template v-else>
                {{ dateFromDateTo }}
              </template>
            </p>
          </div>
        </div>
        <filters
          v-if="tableData"
          :color-date-value="paginatedReport"
          :table-data="tableData"
          :columns="columnTable"
          @search="setFilter"
          @refresh-report="refreshReport"
        />
      </div>
      <div
        v-if="!paginatedReport"
        class="flex-1 relative w-72 flex items-center"
      >
        <div
          class="absolute inset-y-0 flex items-center cursor-pointer"
          style="right: 8px"
          @click="searchReport"
        >
          <SearchIcon />
        </div>
        <label for="searchInput">search</label>
        <input
          v-model.lazy="filterString"
          class="rounded focus_outline-none pl-4 pr-8 w-full text-sm font-medium border border-borderColor"
          type="text"
          style="height: 35px"
          id="searchInput"
          @keyup.enter="searchReport"
        />
      </div>
      <div
        v-if="isStartTimeAvailable && clickedRow"
        class="flex-1 flex justify-center items-center"
      >
        <button
          class="focus_outline-none p-0 mx-4"
          :disabled="isMovingBack"
          type="button"
          @click="goToStart('start')"
        >
          <PlaybackIcon />
        </button>
        <button
          class="focus_outline-none p-0 mx-4"
          :disabled="isMovingBack"
          type="button"
          @click="moveBack"
        >
          <PlaybackLeftIcon />
        </button>
        <button
          v-if="playMarker"
          class="w-3 focus_outline-none p-0 mx-4"
          :disabled="isMovingBack"
          type="button"
          @click="markerPlay"
        >
          <PlayIcon />
        </button>
        <button
          v-else
          class="w-3 focus_outline-none p-0 mx-4"
          type="button"
          @click="markerPause"
        >
          <StopIcon />
        </button>
        <button
          class="focus_outline-none p-0 mx-4"
          type="button"
          @click="focusTrack"
        >
          <TargetIcon />
        </button>
        <button
          class="focus_outline-none p-0 mx-4"
          :disabled="isMovingBack"
          type="button"
          @click="goToStart('end')"
        >
          <PlaybackRightIcon />
        </button>
        <el-select v-model="speed" class="w-20" @change="markerSpeed">
          <el-option
            v-for="item in markerSpeeds"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
        <div
          class="focus_outline-none p-0 mx-4 cursor-pointer"
          type="button"
          @click="closeTrack"
        >
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="10" cy="10" r="10" fill="#EDEFF3" />
            <rect
              x="5.75781"
              y="4.34277"
              width="14"
              height="2"
              rx="1"
              transform="rotate(45 5.75781 4.34277)"
              fill="#828D9B"
            />
            <rect
              x="15.6562"
              y="5.75684"
              width="14"
              height="2"
              rx="1"
              transform="rotate(135 15.6562 5.75684)"
              fill="#828D9B"
            />
          </svg>
        </div>
      </div>
      <div class="flex-1 flex justify-end gap-3 items-center">
        <div v-if="isBarChartExist">
          <img
            v-if="!isBarShown"
            alt=""
            slot="reference"
            src="@/assets/icons/chart-icon.svg"
            class="cursor-pointer"
            @click="showBarChart"
          />
          <img
            v-else
            slot="reference"
            alt=""
            src="@/assets/icons/chart-icon-shown.svg"
            class="cursor-pointer"
            @click="closeBarChart"
          />
        </div>
        <el-popover
          v-model="isPopoverVisible"
          placement="bottom"
          trigger="hover"
          :key="'hover'"
          popper-class="sizeScreenReport"
        >
          <el-radio-group
            v-model="sizeScreen"
            @change="getHeight($event, isBarShown)"
          >
            <el-radio label="miniScreen">
              {{ $t('reports.reports-size-screen.minimum') }}
            </el-radio>
            <el-radio label="thirdScreen">
              {{ $t('reports.reports-size-screen.thirdScreen') }}
            </el-radio>
            <el-radio label="medScreen">
              {{ $t('reports.reports-size-screen.medium') }}
            </el-radio>
            <el-radio label="maxScreen">
              {{ $t('reports.reports-size-screen.maximum') }}
            </el-radio>
          </el-radio-group>
          <WindowIcon slot="reference" />
        </el-popover>
        <el-popover
          placement="bottom"
          trigger="click"
          :key="'click'"
          popper-class="moreActionsReport"
          width="270"
          v-model="isPopoverDownloadVisible"
        >
          <div v-if="!isDownloadreport" class="flex flex-col">
            <a
              class="cursor-pointer font-bold text-annotationColor mb-1"
              @click="downloadReport"
              >{{ $t('reports.load-report') }}
            </a>
            <span
              class="cursor-pointer select-none font-bold text-annotationColor mb-1"
              @click="printReport"
            >
              {{ $t('reports.print-report') }}
            </span>
            <skif-checkbox
              v-if="
                paginatedReport &&
                paginatedReport.groups &&
                tableType !== 'trips_by_points' &&
                tableType !== 'trips_common'
              "
              v-model="isResultShowen"
              :label="$t('reports.onlyResult')"
            />
            <!-- <span
              v-if="
                !isChartAvailable && isStartTimeAvailable && isChartNotGroup
              "
              class="cursor-pointer select-none font-bold text-annotationColor mb-1"
              @click="showChart(true)"
            >
              {{ $t('reports.show-chart') }}
            </span> -->
            <div v-if="isChartAvailable && isStartTimeAvailable" class="flex">
              <skif-checkbox v-model="isChartShowen" />
              <span
                class="cursor-pointer select-none font-bold text-annotationColor mb-2 ml-2"
                @click="isChartShowen = !isChartShowen"
              >
                {{ $t('reports.connect') }}
              </span>
            </div>
            <div
              v-if="
                isLatLong &&
                !isJournal &&
                !isMotohour &&
                !isDrivingStyle &&
                !isDigitalSensors
              "
              class="flex"
              style="align-items: center; justify-content: flex-start, margin-left:0"
            >
              <skif-checkbox
                id="checkbox_id"
                v-model="isStopShowing"
                class="mr-2"
                :show-label="true"
                :label="$t('reports.display_all_events')"
                @click.native="(event) => event.stopPropagation()"
                @change="changeChecked($event)"
              />
            </div>
            <div v-if="isHaveAnnotationSettings" class="flex">
              <skif-checkbox
                id="checkbox_id"
                :value="isShowAnnotation"
                class="mr-2"
                :show-label="true"
                :label="$t('reports.show_annotation_on_click')"
                @click.native="(event) => event.stopPropagation()"
                @change="changeToggleAnnotation($event)"
              />
              <el-tooltip
                :open-delay="500"
                :content="$t('reports.show_annotation_on_click_hint')"
                :offset="2"
                :visible-arrow="false"
                effect="dark"
                placement="bottom"
              >
                <InfoIcon :color="'#A6AEB8'" />
              </el-tooltip>
            </div>
            <div
              v-if="tableData && tableData.unit_id && showColorTrek"
              class="flex"
              style="
                align-items: center;
                justify-content: flex-start;
                margin-left: 0;
              "
            >
              <span
                class="cursor-pointer select-none font-bold text-annotationColor mb-1"
                @click="openColorDialog"
              >
                {{ $t('reports.show-color') }}
              </span>
            </div>
            <span
              class="cursor-pointer select-none font-bold text-annotationColor mb-1"
              @click="showComposeReport"
            >
              {{ $t('reports.сomposite_report') }}
            </span>
            <el-dialog
              custom-class="companiesColor"
              :title="$t('reports.show-color')"
              :visible.sync="dialogColor"
              :modal-append-to-body="false"
            >
              <color-panel v-model="colorValue" style="margin: 0 auto" />
              <div class="flex justify-end">
                <skif-button
                  class="mt-1"
                  style="width: fit-content; background: none; color: #5477a9"
                  variant="normal"
                  @click="checkedColor"
                >
                  {{ $t('btn.select') }}
                </skif-button>
              </div>
            </el-dialog>
            <p
              v-if="tableType === 'journal'"
              @click="showDeleteMessageModal"
              class="cursor-pointer select-none font-bold text-annotationColor mb-1"
            >
              {{ $t('reports.deleted_messaged') }}
            </p>
            <span
              v-if="tableType === 'messages' && isRolesAddParametrs"
              class="cursor-pointer select-none font-bold text-annotationColor mb-1"
              @click="showAddParametrs"
            >
              {{ $t('add_parametrs.add_parametrs') }}
            </span>
          </div>
          <div v-else class="flex flex-col">
            <p
              class="cursor-pointer font-bold text-annotationColor mb-1"
              @click="downloadCurrentReport('excel')"
            >
              {{ $t('save') }} {{ $t('excel') }}
            </p>
            <p
              class="cursor-pointer font-bold text-annotationColor mb-1"
              @click="downloadCurrentReport('word')"
            >
              {{ $t('save') }} {{ $t('word') }}
            </p>
            <p
              class="cursor-pointer font-bold text-annotationColor mb-1"
              @click="downloadCurrentReport('pdf')"
            >
              {{ $t('save') }} {{ $t('pdf') }}
            </p>
          </div>
          <DotsIcon slot="reference" />
        </el-popover>

        <i
          class="el-icon-close text-2xl cursor-pointer text-darkblue font-semibold ml-3"
          @click="handleCLose"
        />
      </div>
    </div>
    <transition name="slide-fade">
      <div v-show="isBarShown" class="bar-chart-wrapper">
        <BarChart :chart-data="chartData" :style="graphStyles" />
      </div>
    </transition>
    <div ref="magazine" class="overflow-auto relative">
      <div v-if="loading" class="absolute inset-0 bg-white opacity-75">
        <div class="el-loading-spinner">
          <svg class="circular" viewBox="25 25 50 50">
            <circle class="path" cx="50" cy="50" r="20" fill="none" />
          </svg>
        </div>
      </div>
      <table
        v-if="sortedRows && sortedRows.length"
        class="w-full text-darkblue trTable font-SourceSansPro"
      >
        <thead class="text-left">
          <tr>
            <td
              v-for="column in columnTable"
              :key="column.key"
              class="top-0 sticky bg-white px-4"
            >
              <div class="flex items-center">
                <span class="text-md leading-0 font-bold whitespace-no-wrap">
                  {{ column.name }}
                </span>
                <SortUpIcon
                  v-if="column.is_sortable"
                  :color="sortProp === column.key ? '#3C5E8F' : '#91B4E7'"
                  :scale="sortProp === column.key && sortOrder === 'descending'"
                  @click.native="sortChange(column.key)"
                />
              </div>
            </td>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(row, index) in sortedRows"
            :key="index"
            class="cursor-pointer hover_bg-hoverrow"
            :class="{
              isRowSelected: index === isRowClicked,
              isTop: index === 0
            }"
            @click.prevent="showOnMap(index, row._unit_id)"
          >
            <td
              v-for="(cell, index) in columnTable"
              :key="index"
              class="px-4"
              :class="'height:1px !important'"
            >
              <span
                v-if="cell.key !== 'point_name'"
                class="font-semibold whitespace-no-wrap table-text"
                :class="row._is_total ? 'font-bold' : ''"
                v-html="convertToClickableLinks(row[cell.key])"
              ></span>
              <el-tooltip
                v-else
                :open-delay="1000"
                :content="$t('reports.map_note')"
                :offset="2"
                :visible-arrow="false"
                effect="dark"
                placement="bottom"
              >
                <span
                  class="race-point cursor-zoom-in"
                  @click.stop="showPointOnMap(row)"
                >
                  {{ row[cell.key] }}
                </span>
              </el-tooltip>
            </td>
          </tr>
        </tbody>
      </table>

      <p
        v-if="isLoadingReport[reportId] || isSwitchLoading"
        class="reloadReport"
      >
        {{ $t('loading') }}
      </p>
      <div
        v-else-if="
          !isLoadingReport[reportId] && !(sortedRows && sortedRows.length)
        "
        class="not-data"
      >
        <div class="reloadReport">
          <InfoIcon :color="'#20579A'" />
          <div class="text">
            <p class="no-data">{{ $t('reportsTranslater.noData') }}</p>
            <p class="reload" @click="reloadReport">
              {{ $t('reportsTranslater.rewrite') }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="sortedRows && sortedRows.length"
      v-dragged="onDragged"
      class="flex justify-center cursor-move solid grey solid grey"
      style="border-top: 1px solid #e2e8f0; margin-bottom: 1px"
    >
      <el-pagination
        ref="mypagination"
        class="report-pagination ml-6"
        layout="sizes, prev, pager, next, jumper"
        :page-sizes="[20, 50, 100, 500]"
        :pager-count="isJournal ? 5 : 7"
        :page-size="pageSize"
        :total="
          isResultShowen && totalResult
            ? totalResult.report.max_rows
            : maxrowsTableData
        "
        :current-page="pageNumber"
        @size-change="handleSizeChange"
        @prev-click="prevPage"
        @next-click="nextPage"
        @current-change="handleCurrentChange"
      />
      <el-select
        v-if="!loading && isJournal && !paginatedReport.closed_to_change"
        v-model="theRawParam"
        style="min-width: 12rem; max-width: 12rem"
        :class="['select-focus']"
        filterable="filterable"
        collapse-tags="collapse-tags"
        multiple="multiple"
        @visible-change="addRawParam"
        @remove-tag="addRawParam"
      >
        <el-option
          v-for="(item, index) in possibleColumns"
          :key="index"
          :label="item.name"
          :value="item.key"
        />
      </el-select>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line import/no-unresolved
import { reportsApi, adminPanel } from '@/api'
import eventBus from '@/eventBus.js'
import axios from 'axios'
// eslint-disable-next-line import/no-unresolved
import reportTab from '@/mixins/reportTab.js'
import { mapState, mapGetters, mapMutations } from 'vuex'
import leafletImage from 'leaflet-image'
import moment, { updateLocale } from 'moment'
import { allRoles } from '@/constants/roles.js'
import BarChart from '@/utils/BarChart.vue'
import SearchIcon from '@/components/reports/icons/SearchIcon.vue'
import PlaybackIcon from '@/components/reports/icons/PlaybackIcon.vue'
import PlaybackLeftIcon from '@/components/reports/icons/PlaybackLeftIcon.vue'
import PlayIcon from '@/components/reports/icons/PlayIcon.vue'
import StopIcon from '@/components/reports/icons/StopIcon.vue'
import TargetIcon from '@/components/reports/icons/TargetIcon.vue'
import PlaybackRightIcon from '@/components/reports/icons/PlaybackRightIcon.vue'
import WindowIcon from '@/components/reports/icons/WindowIcon.vue'
import DotsIcon from '@/components/reports/icons/DotsIcon.vue'
import SortUpIcon from '@/components/reports/icons/SortUpIcon.vue'
import InfoIcon from '@/components/reports/icons/InfoIcon.vue'

// import mapForRoutes from '@/mixins/mapForRoutes.js'
import mapForReportsTable from '../../map/mixins/mapForMonitoring'

export default {
  name: 'ReportsTable',
  components: {
    filters: () => import('./filters'),
    BarChart,
    SearchIcon,
    PlaybackIcon,
    PlaybackLeftIcon,
    PlayIcon,
    StopIcon,
    TargetIcon,
    PlaybackRightIcon,
    WindowIcon,
    DotsIcon,
    SortUpIcon,
    InfoIcon
  },

  mixins: [reportTab, mapForReportsTable],
  props: {
    map: {
      type: Object,
      default: () => {}
    },
    tracksDone: {
      type: Object,
      default: () => {}
    },
    circleGroupTracks: {
      type: Object,
      default: () => {}
    },
    isLoadingReport: {
      type: Object,
      default: () => {}
    },
    fullTable: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      isSwapping: false,
      isSwitchLoading: false,
      selectedObject: null,
      isInputFocus: false,
      isFoto: false,
      dialogColor: false,
      isChartNotGroup: true,
      isLatLong: false,
      isStopShowing: false,
      currentIndex: '',
      isRowClicked: '',
      debounce: null,
      colorValue: '',
      filterString: '',
      theRawParam: [],
      racesDrawn: [],
      // downloadReport: `${
      //   process.env.NODE_ENV === 'development' ? 'http' : 'https'
      // }://${location.host}/api_v1/report_excel?body=${JSON.stringify(
      //   this.paginatedReport
      // )}`,

      sortProp: null,
      sortOrder: null,
      totalResult: null,
      sortedRows: [],
      isResultShowen: false,
      playMarker: true,
      isMovingBack: false,
      speed: 10,
      markerSpeeds: [
        {
          value: 10,
          label: '10x'
        },
        {
          value: 20,
          label: '20x'
        },
        {
          value: 50,
          label: '50x'
        },
        {
          value: 100,
          label: '100x'
        },
        {
          value: 300,
          label: '300x'
        }
      ],
      isChartShowen: false,
      clickedRow: null,
      isPopoverVisible: false,
      fixedWidthColumns: [
        'first_datepoint',
        'last_address',
        'first_address',
        'last_datepoint',
        '_start_latlon',
        '_end_latlon',
        'stamp',
        'datepoint'
      ],
      pageNumber: 1,

      pageSize: 100,
      sort: {
        prop: 'name',
        order: 'descending'
      },
      dateFormat: '',
      timeFormat: '',
      dateTimeFormat: '',
      dateTitleFrom: '',
      dateTitleTo: '',
      queryConditions: [], // filters,
      isNextPage: false,
      isChartFromTable: false,
      drawnPoint: [],
      // isShowAllEvents: false,
      dialogVisible: false,
      isPopoverDownloadVisible: false,
      isDownloadreport: false,
      isBarShown: false,
      graphStyles: {
        height: '120px',
        position: 'relative'
      },
      selectedRow: null,
      objectOrGroup: []
    }
  },

  computed: {
    ...mapState('reports', {
      polyline: (state) => state.track,
      drawenArrows: (state) => state.drawenArrows,
      drawenMakers: (state) => state.drawenMakers,
      trackByRow: (state) => state.trackByDate,
      tableCurrentId: (state) => state.tableCurrentId,
      openedReports: (state) => state.openedReports,
      racePoints: (state) => state.racePoints,
      pointToDraw: (state) => state.pointToDraw,
      circleTrack: (state) => state.circleTrack,
      mapToUse: (state) => state.mapToUse,
      reportTemplates: (state) => state.templates
    }),
    ...mapState('units', {
      allUnits: (state) => state.units,
      unitGroups: (state) => state.unitsGroups,
      showUnitsGroup: (state) => state.showUnitsGroup
    }),
    ...mapState('login', {
      me: (state) => state.me
    }),
    ...mapGetters('units', ['getTerminalType']),
    ...mapGetters('login', ['activeCompanyId']),
    ...mapGetters('dictionary', ['getParamsByKey']),
    ...mapGetters('reports', [
      'chartTable',
      'getChartIndex',
      'getBarChartData',
      'isShowAnnotation'
    ]),

    isHaveAnnotationSettings() {
      const tableType = [
        'journal',
        'notifications',
        'events',
        'stays',
        'thefts',
        'stops',
        'photos',
        'fillings',
        'chronology',
        'digital_sensors',
        'driving_style'
      ]
      return tableType.includes(this.tableType)
    },

    isBarChartExist() {
      return (
        this.tableData &&
        this.tableData.report &&
        this.tableData.report.is_bar_chart
      )
    },

    chartData() {
      const count = this.getBarChartData ? this.getBarChartData.rows.length : 0
      const backgroundColor = Array(count).fill('#C7CAD7')

      if (this.selectedRow !== null) {
        backgroundColor[this.selectedRow] = '#9FC3F2'
      }

      return {
        labels: this.getBarChartData
          ? this.getBarChartData.rows.map((item) => item.bar_chart_x)
          : [],
        datasets: [
          {
            backgroundColor,
            data: this.getBarChartData
              ? this.getBarChartData.rows.map((item) => item.bar_chart_y)
              : []
          }
        ]
      }
    },

    columnTable() {
      const data = this.columnTableData
        ? this.columnTableData.filter((col) => col.key.charAt(0) !== '_')
        : []
      return data
    },

    showColorTrek() {
      let show = true
      // console.log('showUnitsGroup', this.showUnitsGroup)
      this.showUnitsGroup.forEach((val) => {
        const id =
          this.tableData && Object.keys(this.tableData).length
            ? this.tableData.group_id
              ? this.tableData.group_id
              : this.tableData.unit_id
            : ''
        if (id.indexOf(val.id) !== -1) {
          show = val.show
        }
      })
      return show
    },

    rowTable() {
      return this.rowTableData
    },

    rawParams() {
      const defaultParams = [
        'stamp',
        'datepoint',
        'latitude',
        'longitude',
        'speedkph',
        'imei_md5'
      ]
      return this.paginatedReport.units && this.isJournal
        ? this.getParamsByKey(
            this.getTerminalType(this.paginatedReport.units[0].id)
          ).filter((k) => defaultParams.indexOf(k.value) === -1)
        : []
    },
    isJournal() {
      return this.tableType === 'journal'
    },
    isMotohour() {
      return this.tableType === 'motohour'
    },
    isDigitalSensors() {
      return this.tableType === 'digital_sensors'
    },
    isNotifications() {
      return this.tableType === 'notifications'
    },
    isDrivingStyle() {
      return this.tableType === 'driving_style'
    },
    ...mapGetters('reports', { journalReportId: 'journalReportId' }),
    maxrowsTableData() {
      return this.$store.getters['reports/maxrows'](this.reportId)
    },

    loading() {
      return this.$store.getters['reports/loading'](this.reportId)
    },

    isChartAvailable() {
      return this.$parent.reportTables.some((table) => table.key === 'chart')
    },
    isStartTimeAvailable() {
      if (!this.columnTableData) {
        return false
      }

      if (this.columnTableData.length) {
        return this.columnTableData.some((col) =>
          /^_start_time.*/.test(col.key)
        )
      }

      return false
    },
    ...mapState('trackPlayer', {
      isTrackFinised: (state) => state.isTrackFinised
    }),
    ...mapState('map', {
      eyeTriggered: (state) => state.eyeTriggered.isTrigged
    }),

    tableReportName() {
      if (this.tableData && this.tableData.report) {
        let groupNameOrUnitName = ''
        if (this.tableData.group_name) {
          groupNameOrUnitName = this.tableData.group_name
          this.$store.commit('reports/setGroupOrNormalReport', 'group')
        } else if (this.tableData.unit_name) {
          groupNameOrUnitName = this.tableData.unit_name
          this.$store.commit('reports/setGroupOrNormalReport', 'normal')
        }

        const result = `${this.tableData.report.name},`
        return result
      }
      return ''
    },

    dateFromDateTo() {
      if (this.paginatedReport && this.tableData && this.tableData.report) {
        return `${this.dateTitleFrom} - ${this.dateTitleTo}`
      }
      return ''
    },

    last24hours() {
      const from = moment().startOf('day')
      const to = moment().add(1, 'days').startOf('day')
      return `${from.format(
        `${this.dateFormat} ${this.timeFormat}`
      )} - ${to.format(`${this.dateFormat} ${this.timeFormat}`)}`
    },

    isRolesAddParametrs() {
      return (
        this.me.role.key === allRoles.TARIFF_EDITOR ||
        this.me.role.key === allRoles.CONTROLLER
      )
    }
  },

  watch: {
    async getChartIndex(val) {
      this.sortOrder = null
      this.sortProp = null

      const pageSize = Math.ceil(val / this.pageSize)
      let key = val
      if (val > this.pageSize) {
        key = val - this.pageSize * (pageSize - 1)
      }
      if (pageSize !== this.pageNumber) {
        await this.handleCurrentChange(pageSize, key)
      } else {
        await this.showOnMap(key)
        await this.scrollToItem('isRowSelected')
      }
    },

    pointToDraw(val) {
      console.log('ok', this.racesDrawn)
      // this.drawnPoint.length ? this.removeMarkers([this.drawnPoint]) : ''
      this.racesDrawn.forEach((point, index) => {
        this.map.hasLayer(point) ? this.map.removeLayer(point) : ''
        this.racesDrawn.slice(index, 1)
      })
      this.drawPointRace(val)
      const bounds = L.latLngBounds(val.geometry)
      this.map.fitBounds(bounds)
    },
    racePoints(val) {
      this.removeMarkers.length ? this.removeMarkers(this.racesDrawn) : ''
      const pointsArr = []
      val.forEach((geozone) => {
        this.drawPointRace(geozone)
        pointsArr.push(geozone.geometry)
      })

      const bounds = L.latLngBounds(pointsArr)
      this.map
        .flyToBounds(bounds, { animate: false, duration: 55.5 })
        .getCenter()
    },
    openedReports(newq, old) {},
    isNextPage(val) {
      setTimeout(() => {
        const markers = []
        this.sortedRows.forEach((row, index) => {
          const latlng = row[`_marker_latlon_${this.tableType}`]

          markers.push({
            latlng,
            row,
            tableType: this.tableType
          })
          // data.rows.push(row)
        })
        this.$store.commit('reports/SHOWSTOPS', {
          markers,
          columnTable: this.columnTable
        })
      }, 1000)
    },
    isStopShowing(val) {
      const currentState = val ? 'all' : 'singular'
      this.$store.commit('map/SET_ISALLSTOPS', currentState)
    },
    loading(newVal, oldVal) {
      if (!newVal) {
        // this.sortOrder = ''
        // this.sortProp = ''
        this.sortedRows = this.rowTable

        if (this.sortedRows && this.sortedRows.length > 0) {
          if (
            Object.keys(this.sortedRows[0]).some(
              (k) => ~k.indexOf('_marker_latlon_')
            )
          ) {
            this.isLatLong = true
          }
        }
      }
    },
    isTrackFinised(val) {
      const trackID = Object.keys(val)[0]
      this.isMovingBack = val.isMovingBack
      trackID === this.reportId && (this.playMarker = true)
    },
    tableData: {
      handler(newValue) {
        if (newValue) {
          this.getBarChartDataHandler()
          let groupNameOrUnitName = ''
          if (newValue.group_name) {
            groupNameOrUnitName = newValue.group_name
            this.objectOrGroup = this.unitGroups
          } else if (newValue.unit_name) {
            groupNameOrUnitName = newValue.unit_name
            this.objectOrGroup = this.allUnits
          }
          this.selectedObject = groupNameOrUnitName
        }
      },
      immediate: true
    },

    isResultShowen() {
      this.showResult()
    },
    reportObjects() {}
  },
  methods: {
    ...mapMutations('reports', [
      'SET_CURRENT_TABLE_DATA',
      'CLEAR_TREK',
      'DIRECTIVE_ZOOM',
      'setIsShowAnnotation'
    ]),
    closeTrack() {
      this.$store.commit('reports/closeTrackByRow')
    },
    async switchObject(val) {
      this.$store.commit('reports/switchObject')
      this.isSwitchLoading = true
      if (!this.paginatedReport.groups) {
        this.removeCurrentTrack()
        this.removeMarkers(this.racesDrawn)
        this.racesDrawn = []
        this.CLEAR_TREK(true)
        this.isSwapping = true
        this.$emit('switchObject')
      }

      const data = {
        zoom: 14,
        units: this.paginatedReport.units,
        from: this.paginatedReport.from,
        to: this.paginatedReport.to
      }

      const reportData = {
        ...this.paginatedReport,
        max: this.pageSize,
        offset: (this.pageNumber - 1) * this.pageSize,
        sortField: this.sortProp || null,
        sortDesc: this.sortOrder === 'descending',
        conditions: this.queryConditions
      }

      if (!this.paginatedReport.groups) {
        const { units, template_tables, from, to } = this.paginatedReport
        const oldId = template_tables[0].id + units[0].id + from + to
        const newId = template_tables[0].id + val + from + to
        this.sortedRows = []
        reportData.objectsToSwitch = { oldId, newId }
        this.paginatedReport.units[0].id = val
      } else {
        const { groups, template_tables, from, to } = this.paginatedReport
        const oldId = template_tables[0].id + groups[0].id + from + to
        const newId = template_tables[0].id + val + from + to
        this.sortedRows = []
        reportData.objectsToSwitch = { oldId, newId }
        this.paginatedReport.groups[0].id = val
      }

      await this.$store.dispatch('reports/GET_REPORT', reportData).then(() => {
        this.isSwitchLoading = false
      })

      if (!this.paginatedReport.groups) {
        this.$store.dispatch('reports/GET_TRACK', data).then(() => {
          this.$store.commit('reports/CLOSED_CALL_DRAW', false)
        })
      }
    },
    convertToClickableLinks(text) {
      if (typeof text !== 'string') {
        return text
      }

      const urlRegex = /(https?:\/\/[^\s]+)/g
      return text.replace(urlRegex, (url) => {
        return `<a class='table-link font-bold' href="${url}" onclick="event.stopPropagation();" target="_blank" rel="noopener noreferrer">${url}</a>`
      })
    },
    stopPropagation(event) {
      event.stopPropagation()
    },
    async getBarChartDataHandler() {
      const reportsForm = JSON.parse(
        localStorage.getItem(
          `reportsForm${this.me.id}${this.me.active_company.id}`
        )
      )
      const openedReports = JSON.parse(localStorage.getItem('openedReports'))

      if (this.isBarChartExist) {
        await this.$store.dispatch('reports/GET_BAR_CHART_REPORT', {
          groups: [{ id: reportsForm.unitsId }],
          template_tables: openedReports.length
            ? [{ id: this.paginatedReport.tableId }]
            : [],
          from: reportsForm.datePicker[0],
          to: reportsForm.datePicker[1]
        })
      }
    },
    showBarChart() {
      this.isBarShown = true

      this.getHeight(this.sizeScreen, this.isBarShown)
    },
    closeBarChart() {
      this.isBarShown = false
      this.getHeight(this.sizeScreen)
    },
    scrollToItem(className) {
      if (this.$refs.magazine) {
        if (!document.querySelector(`.${className}`)) return
        this.$refs.magazine.scrollTo({
          top: document.querySelector(`.${className}`).offsetTop - 50,
          behavior: 'smooth'
        })
      }
    },
    drawPointRace(geozone) {
      if (geozone.geometry && geozone.geometry.length !== 0) {
        switch (geozone.type.key) {
          case 'polygon':
            const polygon = L.polygon(geozone.geometry, {
              color: 'red',
              opacity: 0.3,
              type: geozone.type.key
            }).bindTooltip(geozone.name, {
              pane: 'geozonenamePane',
              permanent: true,
              className: 'geozone-label-red',
              direction: 'bottom'
            })
            polygon.addTo(this.map)
            this.racesDrawn.push(polygon)
            break
          case 'circle':
            const circle = L.circle(geozone.geometry[0], {
              radius: geozone.width,
              color: 'red',
              weight: 1,
              type: geozone.type.key
            }).bindTooltip(geozone.name, {
              pane: 'geozonenamePane',
              permanent: true,
              className: 'geozone-label-red',
              direction: 'bottom'
            })
            circle.addTo(this.map)
            this.racesDrawn.push(circle)
            break
          case 'line':
            const line = L.polyline(geozone.geometry, {
              color: 'red',
              weight: 1,
              type: geozone.type.key
            }).bindTooltip(geozone.name, {
              pane: 'geozonenamePane',
              permanent: true,
              className: 'geozone-label-red',
              direction: 'bottom'
            })
            line.addTo(this.map)
            this.racesDrawn.push(line)
            break
          case 'point':
            const marker = L.marker(geozone.geometry[0], {
              iconUrl: 'images/marker-icon.png',
              shadowUrl: 'images/marker-shadow.png'
            })
            marker.addTo(this.map)
            this.racesDrawn.push(marker)
            break
          default:
            break
        }
      }
    },
    showPointOnMap(row) {
      console.log('row clicked', {
        id: row._trip_id,
        index: row._point_number
      })
      this.$store.dispatch('reports/SHOW_TRIP_REPORT_POINT', {
        id: row._trip_id,
        index: row._point_number
      })
    },
    removeMarkers(geozonesToDelete) {
      geozonesToDelete.forEach((geo) => {
        if (this.map.hasLayer(geo)) {
          geo.removeFrom(this.map)
        }
      })
    },
    checkedColor() {
      const color = {
        id: this.tableData.unit_id,
        color: this.colorValue,
        from: this.paginatedReport.from,
        to: this.paginatedReport.to,
        type: this.tableData ? this.tableData.report.key : ''
      }
      this.$store.dispatch('reports/COLOR_COMPANY', color)
      this.dialogColor = false
    },
    openColorDialog() {
      this.dialogColor = true
      const data = {
        ...this.tableData,
        ...this.paginatedReport
      }
      this.SET_CURRENT_TABLE_DATA(data)
    },

    downloadReport() {
      this.isDownloadreport = true
    },

    downloadCurrentReport(type) {
      if (this.paginatedReport) {
        this.paginatedReport.only_totals = this.isResultShowen
        const body = this.paginatedReport
        const url = `${window.location.protocol}//${location.host}/api_v1/report_${type}`

        if (this.isJournal) {
          axios
            .post(url, body, { responseType: 'blob' })
            .then((response) => {
              const url = window.URL.createObjectURL(new Blob([response.data]))
              const disposition = response.headers['content-disposition']
              const matches = /filename=([^;]+)/gi.exec(disposition)
              const filename = (matches[1] || 'all_reports').trim()
              const link = document.createElement('a')
              link.href = url
              link.setAttribute('download', `${filename}`)
              document.body.appendChild(link)
              link.click()
            })
            .catch(async (error) => {
              const responseObj = await error.response.data.text()
              const errorMessage = JSON.parse(responseObj)
              this.$notify.error({
                duration: 5000,
                title: `${this.$t('error')} \u{1f62a}`,
                message: errorMessage.message
              })
            })
        } else {
          axios
            .post(url, body, { responseType: 'blob' })
            .then((response) => {
              const url = window.URL.createObjectURL(new Blob([response.data]))
              const disposition = response.headers['content-disposition']
              const matches = /filename=([^;]+)/gi.exec(disposition)
              const filename = (matches[1] || 'all_reports').trim()
              const link = document.createElement('a')
              link.href = url
              link.setAttribute('download', `${filename}`)
              document.body.appendChild(link)
              link.click()
            })
            .catch(async (error) => {
              const responseObj = await error.response.data.text()
              const errorMessage = JSON.parse(responseObj)
              this.$notify.error({
                duration: 5000,
                title: `${this.$t('error')} \u{1f62a}`,
                message: errorMessage.message
              })
            })
        }
      } else {
        let searchString = ''
        if (this.filterString !== '') {
          searchString = `"search":"${this.filterString}"`
        }
        const url = `${window.location.protocol}//${location.host}/api_v1/finance_excel`
        const body = `{${searchString}}`
        axios
          .post(url, body, { responseType: 'blob' })
          .then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', `finance_report.${type}`)
            document.body.appendChild(link)
            link.click()
          })
          .catch(async (error) => {
            const responseObj = await error.response.data.text()
            const errorMessage = JSON.parse(responseObj)
            this.$notify.error({
              duration: 5000,
              title: `${this.$t('error')} \u{1f62a}`,
              message: errorMessage.message
            })
          })
      }
      this.isDownloadreport = false
    },
    handleCLose() {
      // this.$store.commit('reports/CHANGE_MAGAZINE_DEPEND', null)
      // if (this.circleTrack && this.map.hasLayer(this.circleTrack)) {
      //   this.map.removeLayer(this.circleTrack)
      // }
      // const dataTable = {
      //   ...this.tableData,
      //   ...this.paginatedReport
      // }
      // this.SET_CURRENT_TABLE_DATA(dataTable)

      // const data = {
      //   markers: [],
      //   tableType: this.tableType,
      //   rows: []
      // }
      // this.removeCurrentTrack()
      // this.removeMarkers(this.racesDrawn)
      // this.racesDrawn = []
      // this.$store.dispatch('reports/COLOR_COMPANY_CLEAR', dataTable)
      // this.$store.commit('reports/SHOWSTOPS', data)
      // if (this.isFoto) {
      //   this.$emit('removefotoelements')
      // }
      // this.CLEAR_TREK(true)
      // // remove fast tracks monitoring from map
      // Object.values(this.activeMonitoringTracks).forEach((track) => {
      //   this.map.removeLayer(track)
      // })
      // this.activeMonitoringTracks = {}
      this.$emit('close')
    },

    changeChecked($event) {
      const markers = []
      const isChecked = $event.target.checked

      if (isChecked) {
        this.sortedRows.forEach((row, index) => {
          const latlng = row[`_marker_latlon_${this.tableType}`]

          markers.push({
            latlng,
            row,
            tableType: this.tableType
          })
        })
        this.$store.commit('reports/SHOWSTOPS', {
          markers,
          columnTable: this.columnTable
        })
      } else {
        markers.latlng = []
        this.$store.commit('reports/SHOWSTOPS', {
          markers,
          columnTable: this.columnTable
        })
      }
      // let layerGroup = L.layerGroup(markers)
    },

    goToStart(status) {
      const tableKey = this.tableData ? this.tableData.report.key : ''
      const map = this.$parent.$refs['leaflet-map']
      const start =
        this.sortedRows[this.currentIndex][`_start_latlon_${tableKey}`]
      const end = this.sortedRows[this.currentIndex][`_end_latlon_${tableKey}`]
      status === 'start' ? map.setView(start) : map.setView(end)
    },

    searchReport() {
      this.$store.dispatch('reports/GET_REPORT', {
        isFinance: true,
        searchString: this.filterString
      })
    },
    setFilter(opt) {
      this.queryConditions = opt ? [opt] : []
      const setFilter = true
      this.getTableData(setFilter)
    },
    sortChange(prop) {
      if (this.loading) {
        return
      }
      this.sortProp = prop
      if (this.sortOrder === 'ascending') {
        this.sortOrder = 'descending'
      } else if (this.sortOrder === 'descending') {
        this.sortOrder = ''
        this.sortProp = ''
      } else {
        this.sortOrder = 'ascending'
      }
      this.getTableData()
      // console.log('sortChange')
    },
    addRawParam(isAppearing) {
      if (!isAppearing || typeof isAppearing === 'string') {
        this.paginatedReport.selected_columns = this.theRawParam.map(
          (param) => {
            return {
              name: param,
              key: param
            }
          }
        )
        console.log('addRawParam')
        this.getTableData()
      }
    },
    markerPlay() {
      this.playMarker = false
      const iconKey = this.unit(this.clickedRow._unit_id)
        ? this.unit(this.clickedRow._unit_id).icon.key
        : 'van-vw'
      this.$store.commit('trackPlayer/PLAY_MOVINGMARKER', [
        this.reportId,
        iconKey
      ])
    },
    markerPause() {
      this.playMarker = true
      this.$store.commit('trackPlayer/PAUSE_MOVINGMARKER', this.reportId)
    },
    moveBack() {
      this.$store.commit('trackPlayer/MOVE_MARKER_BACK', this.reportId)
    },
    focusTrack() {
      this.$store.commit('trackPlayer/FITBOUNDS_TO_TRACKBYDATE', this.reportId)
    },
    markerSpeed() {
      this.$store.commit('trackPlayer/CHANGE_SPEED', {
        [this.reportId]: this.speed
      })
    },

    makeBoldTotalRow({ row, rowIndex }) {
      return row._is_total && row === this.clickedRow
        ? 'font-bold clicked-row'
        : row._is_total
        ? 'font-bold'
        : row === this.clickedRow
        ? 'clicked-row'
        : ''
    },
    showChart(value) {
      const tableKey = this.tableData ? this.tableData.report.key : ''
      const row = this.clickedRow
      const chartReportData = {
        reportFormData: {
          ...this.paginatedReport,
          template_tables: [{ id: this.chartTable.id }]
        },
        tableInfo: {
          ...this.chartTable,
          tableType: 'reportChart',
          reportId: this.chartTable.id
        }
      }

      this.CLEAR_TREK(false)
      // console.log('chartreportdata', chartReportData)
      if (row) {
        this.$store.commit('reports/SET_CHART_ZOOM', [
          row[`_start_time_${tableKey}`],
          row[`_end_time_${tableKey}`]
        ])
        this.$parent.showReport({ ...chartReportData, isZoomed: true })
      } else {
        this.$parent.showReport({ ...chartReportData, isZoomed: false })
      }
    },
    showResult() {
      const params = {
        ...this.paginatedReport,
        only_totals: this.isResultShowen
      }

      if (!this.isResultShowen) {
        params.max = 100
        params.offset = 0
      }

      this.$store.dispatch('reports/GET_REPORT', params)
    },
    showOnMap(index) {
      this.selectedRow = index

      const reportsForm = JSON.parse(
        localStorage.getItem(
          `reportsForm${this.me.id}${this.me.active_company.id}`
        )
      )
      this.currentIndex = index
      this.isRowClicked = index
      this.clickedRow = []
      const row = this.rowTableData[index]
      this.clickedRow = row
      const tableKey = this.tableData ? this.tableData.report.key : ''
      if (this.isStartTimeAvailable) {
        this.playMarker = true
        this.$store.commit('map/REMOVE_MOVINGMARKER', this.reportId)
      }

      if (this.isChartShowen) {
        this.$store.commit('reports/SET_CHART_ZOOM', [
          row[`_start_time_${tableKey}`],
          row[`_end_time_${tableKey}`]
        ])
      }
      if (row._type_events !== 'theft' && row._type_events !== 'filling') {
        //  --- START SHOW TRACK

        if (
          row[`_marker_latlon_${tableKey}`] === '-' ||
          tableKey === 'digital_sensors' ||
          (row[`_start_time_${tableKey}`] &&
            row[`_end_time_${tableKey}`] &&
            row[`_start_time_${tableKey}`] !== '-' &&
            row[`_end_time_${tableKey}`] !== '-' &&
            row[`_start_latlon_${tableKey}`] !== '-')
        ) {
          const start =
            tableKey === 'driving_style'
              ? reportsForm.datePicker[0]
              : row[`_start_time_${tableKey}`]
          const end =
            tableKey === 'driving_style'
              ? reportsForm.datePicker[1]
              : row[`_end_time_${tableKey}`]

          const noTrack =
            (this.paginatedReport.time_group === 'driver' ||
              this.paginatedReport.time_group === 'unit') &&
            tableKey === 'driving_style'

          if (!noTrack) {
            // if (row._is_total) {
            //   return
            // }

            if (start && start !== '-' && end && end !== '-') {
              this.$store.dispatch('reports/GET_TRACK_BYDATE', [
                {
                  zoom: 18,
                  units: [{ id: row._unit_id }],
                  from: start,
                  to: end,
                  geozones:
                    row._geozone_id && row._geozone_id !== '-'
                      ? [{ id: row._geozone_id }]
                      : ''
                },
                this.reportId,
                this
              ])
            }
          }
        }
      }

      // --- START SHOW START/FINISH MARKERS

      if (
        row[`_start_latlon_${tableKey}`] &&
        row[`_end_latlon_${tableKey}`] &&
        row[`_start_latlon_${tableKey}`] !== '-' &&
        row[`_end_latlon_${tableKey}`] !== '-'
      ) {
        this.$store.commit('map/SET_STARTENDMARKER', [
          [row[`_start_latlon_${tableKey}`], row[`_end_latlon_${tableKey}`]],
          this.reportId
        ])
      }

      if (tableKey === 'photos' && !row[`_marker_latlon_${tableKey}`]) {
        this.isFoto = true
        this.$emit('handle-foto-row', { ...row, index })
      } else {
        this.isFoto = false
        const obj = {
          latlng: row[`_marker_latlon_${tableKey}`],
          reportId: this.reportId,
          sortedRow: this.sortedRows[index],
          columnTable: this.columnTable,
          isStopShowing: this.isStopShowing,
          tableType: row,
          tableName: this.tableType
        }

        row[`_marker_latlon_${tableKey}`] &&
          row[`_marker_latlon_${tableKey}`] !== '-' &&
          this.$store.commit('map/SET_MARKERDATA', obj)

        this.$store.commit('map/SET_OUTECH_GEOZONES', {
          row
        })
      }
      //  if the report is race and contain _trip_id then show the points on the map
      if (this.rowTableData[index]._trip_id) {
        this.$store.dispatch(
          'reports/SHOW_TRIP_REPORT',
          this.rowTableData[index]._trip_id
        )
      }
    },

    resetPagination() {
      if (this.$refs.mypagination)
        this.$refs.mypagination.internalCurrentPage = 1
    },

    prevPage() {
      this.pageNumber -= 1
      // this.getTableData()
    },

    nextPage() {
      this.pageNumber += 1
      // this.getTableData()
    },
    removeCurrentTrack() {
      this.$store.commit('map/REMOVE_MOVINGMARKER', this.reportId)
      this.$store.commit('reports/SET_REMOVETRACK')
    },
    async getTableData(setFilter, key) {
      this.scrollToItem('isTop')

      const data = {
        ...this.paginatedReport,
        max: this.pageSize,
        // старое
        // offset: (this.pageNumber - 1) * this.pageSize,
        offset: setFilter ? 0 : (this.pageNumber - 1) * this.pageSize,
        sortField: this.sortProp || null,
        sortDesc: this.sortOrder === 'descending',
        conditions: this.queryConditions
      }

      await this.$store.dispatch('reports/GET_REPORT', data).then(async () => {
        if (key || key === 0) {
          await this.showOnMap(key)
          await this.scrollToItem('isRowSelected')
        }
      })
    },

    async handleCurrentChange(val, key) {
      this.pageNumber = val
      await this.getTableData(null, key)
      if (this.isStopShowing) {
        this.isNextPage = !this.isNextPage
        const markers = []
        markers.latlng = []
        await this.$store.commit('reports/SHOWSTOPS', {
          markers: [],
          columnTable: []
        })
      }
      await this.$store.dispatch('map/REMOVESTOPSFROMMAP')
    },
    handleSizeChange(val) {
      this.pageSize = val
      this.pageNumber = 1
      this.getTableData()
    },

    printReport() {
      // this.showChart(true)
      const formData = this.paginatedReport
      const component = this
      if (this.paginatedReport) {
        reportsApi.getReport(
          { formData },
          (response) => {
            let elem = document.querySelector('#printMe')
            if (!elem) {
              elem = document.createElement('div')
              elem.id = 'printMe'
              elem.classList.add('hidden', 'table-responsive')
              document.body.appendChild(elem)
            }

            function doImage(err, canvas) {
              const container = document.createElement('div')
              container.style.display = 'flex'
              container.style.alignItems = 'center'
              container.style.justifyContent = 'center'
              container.style.marginTop = '100px'
              let table = `<div class="d-flex flex-column justify-content-center align-items-center"><h3>${
                response.data.report.name
              }, ${
                response.data.group_name && response.data.group_name.length
                  ? response.data.group_name
                  : response.data.unit_name
              }</h3><h3> ${component.paginatedReport.from} - ${
                component.paginatedReport.to
              } </h3></div><table class="table table-bordered">`
              table += `<thead><tr>`
              const img = document.createElement('img')
              const dimensions = component.mapToUse.getSize()
              img.width = dimensions.x
              img.height = dimensions.y
              img.src = canvas.toDataURL()
              const tableHeader = []
              response.data.report.columns.forEach((col) => {
                if (col.key.charAt(0) !== '_') {
                  tableHeader.push(col.key)
                  table += `<th>${col.name}</th>`
                }
              })
              table += `</tr></thead><tbody>`
              response.data.report.rows.forEach((row) => {
                let indexTr = 0
                table += `<tr>`
                Object.keys(row).forEach((rowKey) => {
                  if (rowKey.charAt(0) !== '_') {
                    table += `<td>${
                      row[tableHeader[indexTr]]
                        ? row[tableHeader[indexTr]]
                        : '-'
                    }</td>`
                    indexTr += 1
                  }
                })
                table += `</tr>`
              })
              table += `</tbody></table>`
              elem.appendChild(container)
              container.insertAdjacentHTML('beforebegin', table)

              container.appendChild(img)
              component.$htmlToPaper(
                'printMe',
                {
                  styles: [
                    'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css'
                  ]
                },
                () => {}
              )
              if (err) console.log('err', err)

              elem.innerHTML = ''
            }
            const markers = localStorage.getItem('monitoringUnits')
            for (const marker in component.drawenMakers) {
              if (component.mapToUse.hasLayer(component.drawenMakers[marker])) {
                component.mapToUse.removeLayer(component.drawenMakers[marker])
                setTimeout(() => {
                  component.mapToUse.addLayer(component.drawenMakers[marker])
                }, 1000)
              }
            }
            if (component.drawenArrows && component.drawenArrows.reports) {
              component.drawenArrows.reports.eachLayer((arrow) => {
                arrow.removeFrom(component.mapToUse)
                setTimeout(() => {
                  arrow.removeFrom(component.mapToUse)
                }, 1000)
              })
            }
            leafletImage(this.mapToUse, doImage)
          },
          (error) => {
            console.log(error)
          }
        )
      } else {
        adminPanel.getFinance(
          this.filterString,
          (response) => {
            let elem = document.querySelector('#printMe')
            if (!elem) {
              elem = document.createElement('div')
              elem.id = 'printMe'
              elem.classList.add('hidden', 'table-responsive')
              document.body.appendChild(elem)
            }
            const container = document.createElement('div')
            container.style.display = 'flex'
            container.style.alignItems = 'center'
            container.style.justifyContent = 'center'
            container.style.marginTop = '100px'
            let table = `<div class="d-flex flex-column justify-content-center align-items-center"><h3>${response.data.report.name}, ${response.data.group_name}</h3></div><table class="table table-bordered">`
            table += `<thead><tr>`

            response.data.report.columns.forEach((col) => {
              if (col.key.charAt(0) !== '_') table += `<th>${col.name}</th>`
            })
            table += `</tr></thead><tbody>`
            response.data.report.rows.forEach((row) => {
              table += `<tr>`
              Object.keys(row).forEach((rowKey) => {
                if (rowKey.charAt(0) !== '_') table += `<td>${row[rowKey]}</td>`
              })
              table += `</tr>`
            })
            table += `</tbody></table>`
            elem.appendChild(container)
            container.insertAdjacentHTML('beforebegin', table)
            component.$htmlToPaper(
              'printMe',
              {
                styles: [
                  'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css'
                ]
              },
              () => {}
            )

            elem.innerHTML = ''
          },
          (error) => {
            console.log(error)
          }
        )
      }
    },

    showDeleteMessageModal() {
      const form = {
        id: this.tableData.unit_id,
        name: this.tableData.unit_name
      }
      this.$emit('show-delete-message-modal', form)
    },
    showComposeReport() {
      const formData = {
        tableData: this.tableData,
        report: this.paginatedReport
      }
      if (this.paginatedReport.units) {
        formData.units = this.allUnits.find(
          (el) => el.id === this.paginatedReport.units[0].id
        )
      } else if (this.paginatedReport.groups) {
        formData.groups = this.allUnits.find(
          (el) => el.id === this.paginatedReport.groups[0].id
        ) || [{ id: this.paginatedReport.groups[0].id }]
      }
      this.$emit('show-compose-report', formData)
    },

    showAddParametrs() {
      this.$emit('show-add-parametrs', this.thisUnit)
    },

    async refreshReport() {
      this.$emit('refresh-report', true)

      const data = {
        ...this.paginatedReport,
        max: this.pageSize,
        // старое
        // offset: (this.pageNumber - 1) * this.pageSize,
        offset: (this.pageNumber - 1) * this.pageSize,
        sortField: this.sortProp || null,
        sortDesc: this.sortOrder === 'descending',
        conditions: this.queryConditions
      }
      // this.handleCurrentChange()
      await this.$store.dispatch('reports/GET_REPORT', data)
    },

    reloadReport() {
      this.$emit('reload-report')
      this.$emit('close')
    },

    changeToggleAnnotation(event) {
      this.setIsShowAnnotation(event.target.checked)
      localStorage.setItem('isShowAnnotationOnClick', this.isShowAnnotation)
    }
  },
  async mounted() {
    this.$store.dispatch('reports/PRINT_IMAGE', true)
    this.$store.commit('reports/CHANGE_MAGAZINE_DEPEND', false)
    const isShowAnnotationOnClick = JSON.parse(
      localStorage.getItem('isShowAnnotationOnClick')
    )
    if (isShowAnnotationOnClick) {
      this.setIsShowAnnotation(isShowAnnotationOnClick)
    }
  },
  created() {
    this.dateFormat = this.me.active_company.dateformat.key.toUpperCase()
    this.timeFormat = this.me.active_company.timeformat.key
    this.dateTimeFormat = `${this.dateFormat} ${this.timeFormat}`

    const dateFromFull = this.paginatedReport.from.split(' ')
    const dateFrom = moment(dateFromFull[0]).format(this.dateFormat)
    const timeFrom = moment(dateFromFull[1], this.timeFormat).format(
      this.timeFormat
    )
    this.dateTitleFrom = `${dateFrom} ${timeFrom}`

    const dateToFull = this.paginatedReport.to.split(' ')
    const dateTo = moment(dateToFull[0]).format(this.dateFormat)
    const timeTo = moment(dateToFull[1], this.timeFormat).format(
      this.timeFormat
    )
    this.dateTitleTo = `${dateTo} ${timeTo}`
    this.paginatedReport.units
      ? (this.isChartNotGroup = true)
      : (this.isChartNotGroup = false)
  },

  beforeDestroy() {
    this.map.off('almost:over')
    this.map.off('almost:move')
    this.map.off('almost:out')
    // this.map.removeLayer(this.circle);
    // удаляем групповой трек
    if (this.tracksDone) {
      // удялаем слои almost for circle
      // eslint-disable-next-line no-restricted-syntax, guard-for-in, no-underscore-dangle
      for (const layer in this.tracksDone._layers) {
        this.map.almostOver.removeLayer(this.tracksDone._layers[layer])
      }
      this.map.removeLayer(this.tracksDone)
      this.tracksDone.unbindPopup()
    }
  }
}
</script>

<style lang="stylus" scoped>
.race-point:hover{
  color: #3d5578
}
.trTable tr>td {
  height: 1px;
  line-height: 1.1;
  font-size: 12px;
  color: #5477a9 !important;
}

.bottom-side {
  background: #fff;
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}

.middle {
  .el-radio-group {
    padding-bottom: 26px;
    display: flex;
    justify-content: space-between;
  }
}

.table-text {
  font-size: 12px;
}

.chartBtn {
  border: 2px solid #5477A9;
  border-radius: 4px;
  width: 20px;
  height: 20px;
  padding: 0;
  margin: 0 4px;
}

.isRowSelected {
  background: #EEF5FF;
}

.report-pagination {
  margin: 0;
  padding: 0;
}

.report-pagination {
  margin-right: 25px;
}
</style>
<style lang="stylus">
.report {
  min-height: 200px;
}
.bar-chart-wrapper {
  height: max-content;
}

.isBarShown {
  grid-template-rows: auto auto 1fr auto !important;
}

.select-focus .el-input input {
  background: #fff;
  animation-duration: 0.8s;
  animation-name: blink;
  animation-iteration-count: 5;
}

@keyframes blink {
  from {
    background-color: #fff;
  }

  to {
    background: #cfdbeb;
  }
}

.report-pagination .btn-prev,
.report-pagination .btn-next,
.report-pagination ul,
.report-pagination .el-pagination__jump {
  margin-top: 2px;
}

.report-pagination .el-pagination__sizes {
  width: 138px;
  margin-top: 2px;
  padding-right: 5px;
}

.report-pagination .el-pagination__sizes .el-select--mini {
  width: 100%;
}

.report-pagination .el-pagination__sizes .el-select--mini .el-input {
  width: 100%;
}

.el-dialog {
  width: fit-content;
}

.el-dialog__body {
  padding: 13px 9px;
}

.el-dialog__header {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 13px 9px;
}

.hideElement {
  display: none !important;
}

.slide-fade-enter-active {
  transition: all 0.5s linear;
}
.slide-fade-leave-active {
  transition: all 0.5s linear;
}
.reloadReport {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  display: flex;
  align-items: center;
  padding: 10px 12px;
  background-color: #dbebff;
  border-radius: 12px;
  .text {
    margin-left: 8px;
    color: #20579a;
    .no-data {
      font-size: 12px;
    }
    .reload {
      font-size: 14px;
      font-weight: bold;
      text-decoration: underline;
      cursor: pointer;
    }
  }
}

.not-data {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  border-radius: 0 0 12px 12px;
  z-index: 21;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
}
.table-link {
  color: #0e6aba;
  margin-left: 2px;
  margin-right: 2px;
  text-decoration: underline;
}
.custom-select {
  width: 200px !important;
  height: 24px !important;
  margin-left: 3px !important;
  & .el-input__inner {
    height: 24px !important;
  }
}
.custom-select .el-input__suffix .el-input__icon {
  color: #595959 !important;
}
.report-date {
  font-family: Source Sans Pro;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  color: #828d9b;
  margin-top: 2px;
}
</style>
